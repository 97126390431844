<template>
    <div class="checkNum">
        <div class="content">
            <div class="image"></div>
            <div class="tig">请选择文件数量</div>
            <div class="stepper">
                <van-stepper v-model="fileNum" theme="round" button-size="22" />
                <span>份</span>
            </div>
        </div>
        <div v-if="loading" class="btn disable">下一步</div>
        <div v-else class="btn" @click="goTo">下一步</div>
    </div>
  </template>
  
  <script>
  import {addStorageRecord} from  '@/api/carryOn.js'
  import { Image,Toast,Dialog,Stepper } from "vant";
  import { mapState } from "vuex";
  
  export default {
    name: "carryOn",
    components: {
      [Image.name]: Image,
      [Dialog.Component.name]: Dialog.Component,
      [Stepper.name]:Stepper
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.userInfo,
      }),
      isMiniprogram(){
        return window.__wxjs_environment === 'miniprogram' 
      }
    },
    data() {
      return {
        fileNum:1,
        loading:false
      };
    },
    mounted() {

    },
    methods: {
        async goTo(){
          try {
            this.loading=true; 
            const params = {
              ...this.$route.query,
              fileNum:this.fileNum,
              phone:this.userInfo.phone,
            }
            const {data} = await addStorageRecord(params)
            this.$router.push({name:'carryOnCode',query:{name:data.pickupPerson,cunPhone:data.phone,phone:data.pickupPhone,projId:data.projId}})
          } catch (error) {
            this.loading=false; 
          }
        }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .checkNum {
    width: 100%;
    height: 100vh;
    padding: 8px 10px;
    background: #f5f7fa;
    .content{
        position: relative;
        display: flex;
        justify-content: space-between;
        color: #000000;
        font-size: 16px;
        padding: 12px 18px;
        background-color: #fff;
        .stepper{
            align-self: flex-end;
            display: flex;
            align-items: center;
        }
        .tig{
            position: absolute;
            top: 20px;
            left: 108px;
            font-size: 16px;
            color: #333333;
        }
        ::v-deep .van-stepper{
            .van-stepper__minus{
                color: #707070;
                border-color: #707070;
            }
            .van-stepper__plus{
                background-color: #3385FF;
                margin-right: 4px;
            }
            input{
                width: 48px;
                margin: 0 6px;
                border: 1px solid #707070;
                border-radius: 1px;
            }
        }
    }
    .image{
       width: 75px; 
       height: 75px;
       border-radius: 10px;
       background-color: rgba($color: #1989F7, $alpha: 0.2);
       background-image: url('~@/assets/carryOn/file.png');
       background-repeat: no-repeat;
       background-size: 46px 36px;
       background-position: center;
    }
    .btn{
        color: #fff;
        background-color: #1989F7;
        border-radius: 4px;
        position: absolute;
        font-size: 16px;
        text-align: center;
        line-height: 38px;
        width: calc(100% - 32px);
        left: 16px;
        bottom: 38px;
        &.disable{
          opacity: 0.5;
        }
    }
  }
  </style>